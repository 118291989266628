import React from "react"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { css } from "@mdx-js/react"
import Layout from "../layouts/layout"

export const query = graphql`
  query($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        title
        author
      }
      body
    }
  }
`

const PostTemplate = ({ data: { mdx: post } }) => (
  <Layout>
    <div
      css={css`
        max-width: 1170px;
        margin: 0 auto;
        padding: 0 8px;
      `}
    >
      <h1>{post.frontmatter.title}</h1>
      <p>post by {post.frontmatter.author}</p>
      <MDXRenderer>{post.body}</MDXRenderer>
    </div>
  </Layout>
)

export default PostTemplate
